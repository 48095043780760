/* global styles */
.sunlight-container {
	clear: both !important;
	position: relative !important;
	margin: 10px 0 !important;
}
.sunlight-code-container {
	clear: both !important;
	position: relative !important;
	border: none;
	border-color: #969696 !important;
	background-color: #FFFFFF !important;
}
.sunlight-highlighted, .sunlight-container, .sunlight-container textarea, .sunlight-container pre {
	font-family: Consolas, Inconsolata, Monaco, "Courier New" !important;
	font-size: 12px !important;
	line-height: 15px !important;
}
.sunlight-highlighted, .sunlight-container textarea {
	color: #000000 !important;
	margin: 0 !important;
}
.sunlight-container textarea {
	padding-left: 0 !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
	padding-right: 0 !important;
}
.sunlight-code-container > .sunlight-highlighted {
	white-space: pre;
	overflow-x: auto;
	overflow-y: hidden; /* ie requires this wtf? */
}
.sunlight-highlighted {
	z-index: 1;
	position: relative;
}
.sunlight-highlighted * {
	background: transparent;
}
.sunlight-line-number-margin {
	float: left !important;
	margin-right: 5px !important;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding: 0 !important;
	padding-right: 4px !important;
	padding-left: 4px !important;
	border-right: 1px solid #CCCCCC !important;
	background-color: #EEEEEE !important;
	color: #848484 !important;
	text-align: right !important;
	position: relative;
	z-index: 3;
}
.sunlight-highlighted a, .sunlight-line-number-margin a  {
	border: none !important;
	text-decoration: none !important;
	font-weight: normal !important;
	font-style: normal !important;
	padding: 0 !important;
}
.sunlight-line-number-margin a {
	color: inherit !important;
}
.sunlight-line-highlight-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 0;
}
.sunlight-line-highlight-overlay div {
	height: 15px;
	width: 100%;
}
.sunlight-line-highlight-overlay .sunlight-line-highlight-active {
	background-color: #E7FCFA;
}

/* menu */
.sunlight-menu {
	background-color: #FFFFCC;
	color: #000000;
}
.sunlight-menu ul {
	margin: 0 !important;
	padding: 0 !important;
	list-style-type: none !important;
}
.sunlight-menu li {
	float: right !important;
	margin-left: 5px !important;
}
.sunlight-menu a, .sunlight-menu img {
	color: #000099 !important;
	text-decoration: none !important;
	border: none !important;
}




.sunlight-string,
.sunlight-char,
.sunlight-heredoc,
.sunlight-heredocDeclaration,
.sunlight-nowdoc,
.sunlight-longString,
.sunlight-rawString,
.sunlight-binaryString,
.sunlight-rawLongString,
.sunlight-binaryLongString,
.sunlight-verbatimString,
.sunlight-diff .sunlight-removed,
.sunlight-scala .sunlight-symbolLiteral {
	color: #990000 !important;
}

.sunlight-ident,
.sunlight-operator,
.sunlight-punctuation,
.sunlight-delimiter,
.sunlight-diff .sunlight-unchanged {
	color: #000000 !important;
}

.sunlight-comment,
.sunlight-xmlDocCommentContent,
.sunlight-nginx .sunlight-ssiCommand,
.sunlight-sln .sunlight-formatDeclaration,
.sunlight-diff .sunlight-added {
	color: #009900 !important;
}
.sunlight-number,
.sunlight-guid,
.sunlight-cdata {
	color: #CC6600 !important;
}

.sunlight-named-ident,
.sunlight-constant,
.sunlight-javascript .sunlight-globalVariable,
.sunlight-globalObject,
.sunlight-python .sunlight-attribute,
.sunlight-nginx .sunlight-context,
.sunlight-httpd .sunlight-context,
.sunlight-haskell .sunlight-class,
.sunlight-haskell .sunlight-type,
.sunlight-lisp .sunlight-declarationSpecifier,
.sunlight-erlang .sunlight-userDefinedFunction,
.sunlight-diff .sunlight-header {
	color: #2B91AF !important;
}
.sunlight-keyword,
.sunlight-languageConstruct,
.sunlight-css
.sunlight-element,
.sunlight-bash .sunlight-command,
.sunlight-specialOperator,
.sunlight-erlang .sunlight-moduleAttribute,
.sunlight-xml .sunlight-tagName,
.sunlight-xml .sunlight-operator,
.sunlight-diff .sunlight-modified {
	color: #0000FF !important;
}
.sunlight-shortOpenTag,
.sunlight-openTag,
.sunlight-closeTag,
.sunlight-xmlOpenTag,
.sunlight-xmlCloseTag,
.sunlight-aspOpenTag,
.sunlight-aspCloseTag,
.sunlight-label,
.sunlight-css .sunlight-importantFlag {
	background-color: #FFFF99 !important;
	color: #000000 !important;
}
.sunlight-function,
.sunlight-globalFunction,
.sunlight-ruby .sunlight-specialFunction,
.sunlight-objective-c .sunlight-messageDestination,
.sunlight-6502asm .sunlight-illegalOpcode,
.sunlight-powershell .sunlight-switch,
.sunlight-lisp .sunlight-macro,
.sunlight-lisp .sunlight-specialForm,
.sunlight-lisp .sunlight-type,
.sunlight-sln .sunlight-sectionName,
.sunlight-diff .sunlight-rangeInfo {
	color: #B069AF !important;
}

.sunlight-variable,
.sunlight-specialVariable,
.sunlight-environmentVariable,
.sunlight-objective-c .sunlight-messageArgumentName,
.sunlight-lisp .sunlight-globalVariable,
.sunlight-ruby .sunlight-globalVariable,
.sunlight-ruby .sunlight-instanceVariable,
.sunlight-sln .sunlight-operator {
	color: #325484 !important;
}
.sunlight-regexLiteral,
.sunlight-lisp .sunlight-operator,
.sunlight-6502asm .sunlight-pseudoOp,
.sunlight-erlang .sunlight-macro {
	color: #FF00B2 !important;
}
.sunlight-specialVariable {
	font-style: italic !important;
	font-weight: bold !important;
}
.sunlight-csharp .sunlight-pragma,
.sunlight-preprocessorDirective,
.sunlight-vb .sunlight-compilerDirective,
.sunlight-diff .sunlight-mergeHeader,
.sunlight-diff .sunlight-noNewLine {
	color: #999999 !important;
	font-style: italic !important;
}
.sunlight-xmlDocCommentMeta,
.sunlight-java .sunlight-annotation,
.sunlight-scala .sunlight-annotation,
.sunlight-docComment {
	color: #808080 !important;
}
.sunlight-quotedIdent,
.sunlight-ruby .sunlight-subshellCommand,
.sunlight-lisp .sunlight-keywordArgument,
.sunlight-haskell .sunlight-infixOperator,
.sunlight-erlang .sunlight-quotedAtom {
	color: #999900 !important;
}



/* xml */
.sunlight-xml .sunlight-string {
	color: #990099 !important;
}
.sunlight-xml .sunlight-attribute {
	color: #FF0000 !important;
}
.sunlight-xml .sunlight-entity {
	background-color: #EEEEEE !important;
	color: #000000 !important;
	border: 1px solid #000000 !important;
}
.sunlight-xml .sunlight-doctype {
	color: #2B91AF !important;
}

/* javascript */
.sunlight-javascript .sunlight-reservedWord {
	font-style: italic !important;
}

/* css */
.sunlight-css .sunlight-microsoftFilterPrefix {
	color: #FF00FF !important;
}
.sunlight-css .sunlight-rule {
	color: #0099FF !important;
}
.sunlight-css .sunlight-keyword {
	color: #4E65B8 !important;
}
.sunlight-css .sunlight-class {
	color: #FF0000 !important;
}
.sunlight-css .sunlight-id {
	color: #8A8E13 !important;
}
.sunlight-css .sunlight-pseudoClass,
.sunlight-css .sunlight-pseudoElement {
	color: #368B87 !important;
}

/* bash */
.sunlight-bash .sunlight-hashBang {
	color: #3D97F5 !important;
}
.sunlight-bash .sunlight-verbatimCommand {
	color: #999900 !important;
}
.sunlight-bash .sunlight-variable,
.sunlight-bash .sunlight-specialVariable {
	color: #FF0000 !important;
}

/* python */
.sunlight-python .sunlight-specialMethod {
	font-weight: bold !important;
	color: #A07DD3;
}

/* ruby */
.sunlight-ruby .sunlight-symbol {
	font-weight: bold !important;
	color: #ED7272 !important;
}

/* brainfuck */
.sunlight-brainfuck {
	font-weight: bold !important;
	color: #000000 !important;
}
.sunlight-brainfuck .sunlight-increment {
	background-color: #FF9900 !important;
}
.sunlight-brainfuck .sunlight-decrement {
	background-color: #FF99FF !important;
}
.sunlight-brainfuck .sunlight-incrementPointer {
	background-color: #FFFF99 !important;
}
.sunlight-brainfuck .sunlight-decrementPointer {
	background-color: #66CCFF !important;
}
.sunlight-brainfuck .sunlight-read {
	background-color: #FFFFFF !important;
}
.sunlight-brainfuck .sunlight-write {
	background-color: #99FF99 !important;
}
.sunlight-brainfuck .sunlight-openLoop, .sunlight-brainfuck .sunlight-closeLoop {
	background-color: #FFFFFF !important;
}

/* 6502 asm */
.sunlight-6502asm .sunlight-label {
	font-weight: bold !important;
	color: #000000 !important;
	background: none !important;
}

/* lisp */
.sunlight-lisp .sunlight-macro {
	font-style: italic !important;
}

/* erlang */
.sunlight-erlang .sunlight-atom {
	font-weight: bold !important;
}